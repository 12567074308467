
        import { createElement as _createElement } from 'react';
import { Fragment as _Fragment } from 'react';
import _map from 'lodash-es/map';
export default function relatedItemsRT () {
    function repeatItems1(discountMode, isHasModalDialog, items, itemsIndex) {
        return [items(function () {
                function mergeProps(inline, external) {
                    var res = Object.assign({}, inline, external);
                    if (inline.hasOwnProperty('style')) {
                        res.style = _.defaults(res.style, inline.style);
                    }
                    if (inline.hasOwnProperty('className') && external.hasOwnProperty('className')) {
                        res.className = external.className + ' ' + inline.className;
                    }
                    return res;
                }
                function repeatI2(i, iIndex) {
                    return _createElement('span', {
                        'key': i,
                        'className': 'cm_star cm_star__' + (!(this.review_average - i) || this.review_average - i < 0.25 ? 'empty' : this.review_average - i < 0.75 ? 'half' : 'full')
                    });
                }
                return _createElement('div', { 'className': 'product-item product-item--vertical 1/3--tablet-and-up 1/4--desk' }, _createElement('div', {}, _createElement('div', { 'className': 'product-item__label-list' }, this.stock?.includes('In-stock Only') ? _createElement('span', {
                    'className': 'product-label product-label--custom1',
                    'key': '134'
                }, '\n      In Stock\n    ') : null, this.on_sale && discountMode !== 'percentage' ? _createElement('span', {
                    'className': 'product-label product-label--on-sale',
                    'key': '273'
                }, '\n      Save ', this.formatPrice(this.compare_at_price - this.price), '\n    ') : null, this.on_sale && discountMode === 'percentage' ? _createElement('span', {
                    'className': 'product-label product-label--on-sale',
                    'key': '481'
                }, '\n      Save ', Math.round((this.compare_at_price - this.price) * 100 / this.compare_at_price), '%\n    ') : null), _createElement('a', {
                    'href': this.url,
                    'className': 'product-item__image-wrapper' + (this.image2 ? ' product-item__image-wrapper--with-secondary' : '')
                }, _createElement('div', {
                    'className': 'aspect-ratio aspect-ratio--square',
                    'style': { paddingBottom: '100%' }
                }, _createElement('img', {
                    'className': 'product-item__primary-image image--fade-in lazyautosizes lazyloaded',
                    'src': this.imageOrDefault(this.resizeImage(this.image)),
                    'alt': this.removeHTML(this.title),
                    'onError': this.onImageError
                }), this.image2 ? _createElement('img', {
                    'className': 'product-item__secondary-image image--fade-in lazyautosizes lazyloaded',
                    'src': this.imageOrDefault(this.resizeImage(this.image2)),
                    'alt': this.removeHTML(this.title),
                    'onError': e => this.onImageError(e, 'image2'),
                    'key': '1171'
                }) : null)), _createElement('div', { 'className': 'product-item__info' }, _createElement('div', { 'className': 'product-item__info-inner' }, _createElement('a', mergeProps({
                    'href': this.url,
                    'className': 'product-item__title text--strong link'
                }, { dangerouslySetInnerHTML: { __html: this.breakWordOnSlash(this.title) } })), _createElement('div', { 'className': 'product-item__sku' }, 'SKU: ', this.removeHTML(this.sku)), _createElement('div', { 'className': 'product-item__price-list price-list' }, this.on_sale ? [
                    _createElement('span', {
                        'className': 'price price--highlight',
                        'key': '18261'
                    }, '\n            ', this.price_varies ? `From ${ this.formatPrice(this.price) }` : this.formatPrice(this.price), '\n          '),
                    _createElement('span', {
                        'className': 'price price--compare',
                        'key': '18263'
                    }, this.formatPrice(this.compare_at_price))
                ] : null, !this.on_sale ? _createElement('span', {
                    'className': 'price',
                    'key': '2159'
                }, '\n          ', this.price_varies ? `From ${ this.formatPrice(this.price) }` : this.formatPrice(this.price), '\n        ') : null), [this.review_count ? _createElement.apply(this, [
                        'div',
                        {
                            'className': 'cm_review-stars',
                            'key': '23440'
                        },
                        _map([
                            0,
                            1,
                            2,
                            3,
                            4
                        ], repeatI2.bind(this)),
                        _createElement('span', { 'className': 'cm_review-count' }, '(', this.review_count, ')')
                    ]) : null], this.price ? _createElement('p', {
                    'className': 'affirm-as-low-as',
                    'data-amount': this.price * 100,
                    'data-affirm-type': 'logo',
                    'data-affirm-color': 'blue',
                    'key': '2747'
                }) : null), _createElement('form', {
                    'method': 'post',
                    'action': '/cart/add',
                    'acceptCharset': 'UTF-8',
                    'className': 'product-item__action-list button-stack',
                    'encType': 'multipart/form-data'
                }, _createElement('input', {
                    'type': 'hidden',
                    'name': 'quantity',
                    'value': '1'
                }), _createElement('input', {
                    'type': 'hidden',
                    'name': 'id',
                    'value': this.variant_ids
                }), this.out_of_stock ? _createElement('button', {
                    'className': 'product-item__action-button button button--small button--disabled',
                    'disabled': true,
                    'key': '3160'
                }, '\n        Sold out\n      ') : null, !this.out_of_stock && this.variant_ids.length === 1 ? _createElement('button', {
                    'type': 'submit',
                    'className': 'product-item__action-button product-item__action-button--list-view-only button button--small button--primary',
                    'data-action': 'add-to-cart',
                    'key': '3320'
                }, '\n        Add to cart\n      ') : null, !this.out_of_stock && this.variant_ids.length > 1 ? _createElement('a', {
                    'href': this.url,
                    'className': 'product-item__action-button product-item__action-button--list-view-only button button--small button--primary',
                    'key': '3596'
                }, '\n        Choose options\n      ') : null, isHasModalDialog ? _createElement('button', {
                    'type': 'button',
                    'className': 'product-item__action-button product-item__action-button--list-view-only button button--small button--ternary hidden-phone',
                    'data-action': 'open-modal',
                    'data-secondary-action': 'open-quick-view',
                    'aria-controls': 'modal-quick-view',
                    'data-product-url': this.url,
                    'key': '3845'
                }, '\n        Quick view\n      ') : null))));
            }, { count: undefined })];
    }
    function scopeDiscountModeIsHasModalDialog2() {
        var discountMode = window.theme.discountMode;
        var isHasModalDialog = window.document.querySelector('.modal__dialog');
        return _createElement.apply(this, [
            'div',
            { 'className': 'cm_related-products-list cmRepeater_items' },
            _map(this.items, repeatItems1.bind(this, discountMode, isHasModalDialog))
        ]);
    }
    return _createElement('div', { 'className': 'cm_related-products' }, _createElement('div', { 'className': 'section__header cm_related-products-title-container' }, _createElement('h2', { 'className': 'section__title heading h3 cm_related-products-title' }, 'You might also like...')), scopeDiscountModeIsHasModalDialog2.apply(this, []));
}
        export const componentNames = []