//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-816:_2712,_7788,_2580,_3456,_3160,_5324,_1248,_2076;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-816')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-816', "_2712,_7788,_2580,_3456,_3160,_5324,_1248,_2076");
        }
      }catch (ex) {
        console.error(ex);
      }